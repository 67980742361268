import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

Vue.use(Vuetify);

export default new Vuetify({
    theme: {
        themes: {
            light: {
                primary: '#003145',
                secondary: '#ee4637',
                accent: "#616161",
                error: '#ff5722',

            }
        }
    }
});
