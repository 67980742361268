<template>
  <svg v-if="svg === 'box'" viewBox="0 0 44.97 27.6" class="box">
    <path d="M0,27.6V0H6.14V2H2.49V25.6H6.14v2Z" ></path>
    <path d="M45,27.6H38.83v-2h3.64V2H38.83V0H45Z"></path>
  </svg>
  <svg v-else-if="svg === 'arrow'" viewBox="0 0 50.88 16.78" class="arrow">
    <rect y="7.36" width="48.66" height="2.05" ></rect>
    <polygon
      points="41.86 16.78 40.46 15.28 47.87 8.39 40.46 1.5 41.86 0 50.88 8.39 41.86 16.78"
      >
    </polygon>
  </svg>
</template>

<script>
export default {
  name: "SvgLoader",
  props: ["svg", "fill"],
  components: {},
};
</script>

<style>
</style>
