<template>
  <v-container style="border: 2px solid #003145 !important;" class="transparent rounded-lg" fluid="fluid" :style="'background-color:' + bgcolor">
    <!-- Page d'accueil -->
    <v-container
      class="transparent"
      fluid="fluid"
      v-show="pageAccueil == true"
      :style="'background-color:' + bgcolor"
    >
      <!-- <v-card-text><img src="https://www.recrutementintegral.com/wp-content/themes/theme-recrutement/images/svg/logo.svg" alt="" class="logo mr-16" width="100px"></v-card-text> -->
      <!-- Emploi Vedette -->
      <!-- <v-container v-show="emploisVedette == true" class="primary--text" >
        <div class="font-weight-bold text-center">
            <h1>Emploi Vedette</h1>
        </div>
        <v-item-group>
          <v-container>
            <v-row >
              <v-col cols="12" sm="6"
                md="4" v-for="job in vedetteList" :key="job.AVTRRT__Job__c">
                <v-item>
                  <v-card
                    style="border: 2px solid #003145;"
                    class="ma-2 rounded-lg"
                    outlined
                    width="350">
                    <v-card-text height="250">
                          <v-card-subtitle class="subtitle-2 py-0 text-break">Titre du poste</v-card-subtitle>
                          <v-card-title class="pt-0 black--text text-h5 text-break">{{job.AVTRRT__Job_Title__c}}</v-card-title>
                          <v-card-subtitle v-if="job.Show_Employer__c" class="subtitle-2 py-0 text-break">Employeur</v-card-subtitle>
                          <v-card-subtitle v-if="job.Show_Employer__c" class="py-0 subtitle-1 black--text">{{job.AVTRRT__Account_Job__r.Name}}</v-card-subtitle>
                          <v-card-subtitle class="subtitle-2 py-0 text-break">Ville</v-card-subtitle>
                          <v-card-subtitle class="subtitle-1 black--text py-0 text-break">{{job.AVTRRT__Job_City__c}}</v-card-subtitle>
                          <v-card-subtitle class="subtitle-2 py-0 text-break">Statut de l'emploi</v-card-subtitle>
                          <v-card-subtitle class="subtitle-1 black--text py-0 text-break">{{job.AVTRRT__Job_Term__c}}</v-card-subtitle>
                          <v-card-subtitle v-if="job.Date_Publication__c" style="height: 20px" class="subtitle-1 black--text py-0 text-break">Date de publication: {{ changeDate(job.Date_Publication__c) }}</v-card-subtitle>
                      </v-card-text>

                    <v-card-actions class="justify-end mr-6">

                      BUTTON DESIGN #1
                      <v-btn class="secondary">
                        <span @click="showJobDetail(job)" style="font-size: 36px" class="mdi mdi-arrow-right-thin"></span>
                      </v-btn>

                      BOUTON DESIGN #2
                      <a @click="showJobDetail(job)"><icon class="arrow-container default-color-svg" svg="arrow"></icon><icon class="box default-color-svg" svg="box"></icon></a>
                    </v-card-actions>
                  </v-card>
                </v-item>
              </v-col>
            </v-row>
          </v-container>
        </v-item-group>
      </v-container> -->

      <!-- Published Job -->
      <!-- <div style="height: 450px d-flex flex-wrap justify-center" v-show="publishedJob == true">
        <h1 class="text-center pa-3">Liste d'emplois publié</h1>
        <div class="d-flex flex-wrap justify-center">
          <div class= "ma-4 pa-5 rounded-lg hover conteneur" style="border: 2px solid #003145; height: 280px" v-for="job in publishedJobList" :key="job.AVTRRT__Job__c">
            <h2 class="mb-4" style="max-width: 300px; height: 60px">{{ job.AVTRRT__Job_Title__c }}</h2>
            <p class="font-weight-bold" style="height: 20px">Ville: {{ job.AVTRRT__Job_City__c }}</p>
            <p style="height: 20px">Statut de l'emploi: {{ job.AVTRRT__Job_Term__c }}</p>
            <p v-if="job.Date_Publication__c" style="height: 20px" class="font-italic">Date de publication: {{ changeDate(job.Date_Publication__c) }}
            </p>
            <p>Publié: {{job.AVTRRT__Publish__c}}</p>
          <div class="text-right">
            <v-btn class="secondary mt-4">
              <span @click="showJobDetail(job)" style="font-size: 40px">→</span>
            </v-btn>
          </div>
        </div>
        </div>
        <div class="d-flex justify-center pa-6">
          <v-btn style="border: 2px solid #003145">
            <span class="font-weight-bold" style="max-width: 350px" @click="showList">Voir la liste des emplois disponibles</span>
          </v-btn>
        </div>
      </div> -->
    </v-container>
    <!-- Emplois récent -->
    <v-container
      v-show="emploisRecent == true"
      fluid="fluid"
      class="primary--text transparent"
      :style="'background-color:' + bgcolor"
    >
      <!-- <div class="font-weight-bold text-center">
          <h1>Nos emplois récents</h1>
      </div> -->
      <v-item-group>
        <v-container fluid="fluid" :style="'background-color:' + bgcolor">
          <v-row>
            <v-col
              class="d-flex flex-column justify-space-between"
              cols="12"
              sm="6"
              md="4"
              v-for="job in recentJob"
              :key="job.AVTRRT__Job__c"
            >
              <v-item>
                <v-card
                  style="border: 2px solid #003145 !important"
                  class="ma-2 rounded-lg flex d-flex flex-column"
                  outlined
                  max-width="350"
                >
                  <v-card-text height="250" class="flex d-flex flex-column">
                    <v-card-subtitle class="primary--text subtitle-2 py-0 text-break"
                      >Titre du poste</v-card-subtitle
                    >
                    <v-card-title class="pt-0 black--text text-h5 text-break">{{
                      job.AVTRRT__Job_Title__c
                    }}</v-card-title>
                    <v-card-subtitle class="primary--text subtitle-2 py-0 text-break"
                      >Employeur</v-card-subtitle
                    >
                    <v-card-subtitle class="py-0 subtitle-1 black--text">{{
                      job.Show_Employer__c
                        ? job.AVTRRT__Account_Job__r.Name
                        : "Confidentiel"
                    }}</v-card-subtitle>
                    <v-card-subtitle class="primary--text subtitle-2 py-0 text-break"
                      >Ville</v-card-subtitle
                    >
                    <v-card-subtitle class="subtitle-1 black--text py-0 text-break">{{
                      job.AVTRRT__Job_City__c
                    }}</v-card-subtitle>
                    <v-card-subtitle class="primary--text subtitle-2 py-0 text-break"
                      >Statut de l'emploi</v-card-subtitle
                    >
                    <v-card-subtitle class="subtitle-1 black--text py-0 text-break">{{
                      job.AVTRRT__Job_Term__c
                    }}</v-card-subtitle>
                    <!-- <v-card-subtitle v-if="job.Date_Publication__c" class="subtitle-2 py-0 text-break">Date de publication</v-card-subtitle> -->
                    <!-- <v-card-subtitle v-if="job.Date_Publication__c" class="subtitle-1 black--text py-0 text-break">{{changeDate(job.Date_Publication__c)}}</v-card-subtitle> -->
                  </v-card-text>
                  <v-card-actions class="justify-end mr-6">
                    <!-- <a :href="jobIdURLSearchParams(job.Name)" @click="updateMode('emploi');updateJob(job.Name)"><icon class="arrow-container default-color-svg" svg="arrow"></icon><icon class="box default-color-svg" svg="box"></icon></a> -->
                    <a
                      @click="
                        showJobDetail(job);
                        updateUrl('jobName', job.Name);
                      "
                      ><icon class="arrow-container default-color-svg" svg="arrow"></icon
                      ><icon class="box default-color-svg" svg="box"></icon
                    ></a>
                  </v-card-actions>
                </v-card>
              </v-item>
            </v-col>
          </v-row>
        </v-container>
      </v-item-group>
      <!-- <div class="d-flex justify-center pa-6">
        <v-btn style="border: 2px solid #003145">
          <span class="font-weight-bold" style="max-width: 350px" @click="showList()">Voir la liste des emplois disponibles</span>
        </v-btn>
      </div> -->
    </v-container>
    <!-- Page liste job -->
    <v-container
      fluid="fluid"
      v-show="pageJobList == true"
      class="primary--text transparent"
      :style="'background-color:' + bgcolor"
    >
      <div class="font-weight-bold">
        <div class="d-flex justify-space-between">
          <h2>Filtrer les offres</h2>
          <!-- <v-btn class="primary" @click="returnAccueil">Retour à l'accueil</v-btn> -->
        </div>
        <v-select
          deletable-chips
          chips
          multiple
          label="Categories"
          :items="categoriesList"
          v-model="categorieFiltre"
        >
          <!-- <template v-slot:selection="{ attrs, item, select, selected }">
            <v-chip
                v-bind="attrs"
                :input-value="selected"
                close
                @click="select"
                @click:close="remove(item)">
            <strong>{{item}}</strong>
            </v-chip>
          </template> -->
          <template v-slot:append-item>
            <v-list-item-group v-model="categorieFiltre">
              <v-list-item value="">
                <v-list-item-title> Aucun filtre </v-list-item-title>
              </v-list-item>
            </v-list-item-group>
          </template>
        </v-select>
      </div>
      <!-- <h1 class="text-center" v-if="categorieChoisi != 'Aucun filtre'">{{ categorieChoisi }}</h1> -->
      <v-item-group>
        <v-container fluid="fluid" class="transparent" :style="'background-color:' + bgcolor">
          <v-row v-for="category in categoriesList" :key="category">
            <v-row
              v-if="
                categorieFiltre.includes(category) ||
                !categorieFiltre ||
                !categorieFiltre.length
              "
            >
              <v-card-text
                class="text-h4 pb-0 mt-5"
                v-if="
                  categoryDataLength(grouped[category]) ||
                  (categorieFiltre.includes(category) && !categorieFiltre.length)
                "
                >{{ category }}</v-card-text
              >
              <v-col
                class="d-flex flex-column justify-space-between"
                v-for="(job,i) in grouped[category]"
                :key="job.AVTRRT__Job_Title__c+i"
                :cols="$vuetify.breakpoint.mobile ? ($vuetify.breakpoint.xs ? 12 : 6) : 4"
              >
                <v-item
                  v-if="
                    categorieFiltre.includes(job.AVTRRT__Job_Category__c) ||
                    !categorieFiltre ||
                    !categorieFiltre.length
                  "
                >
                  <v-card
                    style="border: 2px solid #003145 !important"
                    class="ma-2 rounded-lg flex d-flex flex-column"
                    outlined
                    max-width="350"
                  >
                    <v-card-text height="250" class="flex d-flex flex-column">
                      <v-card-subtitle class="primary--text subtitle-2 py-0 text-break"
                        >Titre du poste</v-card-subtitle
                      >
                      <v-card-title class="pt-0 black--text text-h5 text-break">{{
                        job.AVTRRT__Job_Title__c
                      }}</v-card-title>
                      <v-card-subtitle class="primary--text subtitle-2 py-0 text-break"
                        >Employeur</v-card-subtitle
                      >
                      <v-card-subtitle class="py-0 subtitle-1 black--text">{{
                        job.Show_Employer__c
                          ? job.AVTRRT__Account_Job__r.Name
                          : "Confidentiel"
                      }}</v-card-subtitle>
                      <v-card-subtitle class="primary--text subtitle-2 py-0 text-break"
                        >Ville</v-card-subtitle
                      >
                      <v-card-subtitle class="subtitle-1 black--text py-0 text-break">{{
                        job.AVTRRT__Job_City__c
                      }}</v-card-subtitle>
                      <v-card-subtitle class="primary--text subtitle-2 py-0 text-break"
                        >Statut de l'emploi</v-card-subtitle
                      >
                      <v-card-subtitle class="subtitle-1 black--text py-0 text-break">{{
                        job.AVTRRT__Job_Term__c
                      }}</v-card-subtitle>
                      <!-- <v-card-subtitle v-if="job.Date_Publication__c" class="subtitle-2 py-0 text-break">Date de publication</v-card-subtitle> -->
                      <!-- <v-card-subtitle v-if="job.Date_Publication__c" class="subtitle-1 black--text py-0 text-break">{{changeDate(job.Date_Publication__c)}}</v-card-subtitle> -->
                    </v-card-text>
                    <v-card-actions class="justify-end mr-6">
                      <!-- <a :href="jobIdURLSearchParams(job.Name)" @click="updateMode('emploi');updateJob(job.Name)"><icon class="arrow-container default-color-svg" svg="arrow"></icon><icon class="box default-color-svg" svg="box"></icon></a> -->
                      <a
                        @click="
                          showJobDetail(job);
                          updateUrl('jobName', job.Name);
                        "
                        ><icon
                          class="arrow-container default-color-svg"
                          svg="arrow"
                        ></icon
                        ><icon class="box default-color-svg" svg="box"></icon
                      ></a>
                    </v-card-actions>
                  </v-card>
                </v-item>
              </v-col>
            </v-row>
          </v-row>
        </v-container>
      </v-item-group>
      <!-- <v-item-group>
    <v-container>
      <v-row>
        <v-col cols="12" sm="6"
          md="4" v-for="job in jobList.filter(filtrerCategorie)" :key="job.AVTRRT__Job__c">
           <h1>{{job.AVTRRT__Job_Category__c}}</h1>
          <v-item>
            <v-card
              style="border: 2px solid #003145;"
              class="ma-2 rounded-lg"
              outlined
              width="350">
              <v-card-text height="250">
                    <v-card-subtitle class="subtitle-2 py-0 text-break">Titre du poste</v-card-subtitle>
                    <v-card-title class="pt-0 black--text text-h5 text-break">{{job.AVTRRT__Job_Title__c}}</v-card-title>
                    <v-card-subtitle v-if="job.Show_Employer__c" class="subtitle-2 py-0 text-break">Employeur</v-card-subtitle>
                    <v-card-subtitle v-if="job.Show_Employer__c" class="py-0 subtitle-1 black--text">{{job.AVTRRT__Account_Job__r.Name}}</v-card-subtitle>
                    <v-card-subtitle class="subtitle-2 py-0 text-break">Ville</v-card-subtitle>
                    <v-card-subtitle class="subtitle-1 black--text py-0 text-break">{{job.AVTRRT__Job_City__c}}</v-card-subtitle>
                    <v-card-subtitle class="subtitle-2 py-0 text-break">Statut de l'emploi</v-card-subtitle>
                    <v-card-subtitle class="subtitle-1 black--text py-0 text-break">{{job.AVTRRT__Job_Term__c}}</v-card-subtitle>
                    <v-card-subtitle v-if="job.Date_Publication__c" style="height: 20px" class="subtitle-1 black--text py-0 text-break">Date de publication: {{ changeDate(job.Date_Publication__c) }}</v-card-subtitle>
                </v-card-text>

              <v-card-actions class="justify-end mr-6">
                <a @click="showJobDetail(job)"><icon class="arrow-container default-color-svg" svg="arrow"></icon><icon class="box default-color-svg" svg="box"></icon></a>
              </v-card-actions>
            </v-card>
          </v-item>
        </v-col>
      </v-row>
    </v-container>
  </v-item-group> -->
      <!-- fin boucle -->

      <div class="d-flex justify-end pa-4">
        <v-btn @click="formSpont();updateUrl('mode','spont')" class="primary" style="height: 60px">
          <span class="font-weight-bold">Candidature spontanée</span>
        </v-btn>
      </div>
    </v-container>

    <!-- Page détail job -->
    <v-container
      class="transparent"
      fluid="fluid"
      v-show="pageJobDetail == true"
      v-if="jobLoaded == true"
      :style="'background-color:' + bgcolor"
    >
      <div>
        <!-- <span class="primary--text" >Titre du poste:</span> -->
        <h4 class="text-h4 text-break">
          <span class="text-h4 primary--text"> {{ jobDescription.AVTRRT__Job_Title__c }}</span>
        </h4>
        <h4 class="text-h6 text-break">
          <span class="primary--text">Employeur:</span>
          {{
            jobDescription.Show_Employer__c
              ? jobDescription.AVTRRT__Account_Job__r.Name
              : "Confidentiel"
          }}
        </h4>
        <h4 class="text-h6 text-break">
          <span class="primary--text">Ville: </span
          >{{ jobDescription.AVTRRT__Job_City__c }}
        </h4>

        <h4 class="text-h6 text-break">
          <span class="primary--text">Statut de l'emploi:</span>
          {{ jobDescription.AVTRRT__Job_Term__c }}
        </h4>
        <v-btn @click="showForm()" class="rounded-md my-4 secondary">
          <span class="font-weight-bold">Postuler</span>
        </v-btn>
        <div>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <!-- <a :href="'mailto:?subject='+jobDescription.AVTRRT__Job_Title__c+'&body=URL to this job: https://localhost:8080/'+jobIdURLSearchParams(jobDescription.Name)" class="fa fa-solid fa-user" v-bind="attrs" v-on="on"></a> -->
              <a
                :href="
                  'mailto:?subject=' +
                  jobDescription.AVTRRT__Job_Title__c +
                  '&body=URL to this job: ' +
                  encodeURIComponent(getURLShareLink())
                "
                target="_blank"
                class="fa fa-solid fa-user"
                v-bind="attrs"
                v-on="on"
              ></a>
            </template>
            <span>Envoyer à un ami</span>
          </v-tooltip>
          <!-- <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                  <a href="#" class="fa fa-instagram" v-bind="attrs" v-on="on"></a>
              </template>
              <span>Ajouter à Instagram</span>
            </v-tooltip> -->
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <!-- <a :href="'https://www.facebook.com/sharer/sharer.php?u=https%3A%2F%2Fwww.recrutementintegral.com%2Foffres-d-emplois%2F' + encodeURIComponent(jobIdURLSearchParams(jobDescription.Name)) + '&layout&size&width=77&height=20&appId'" target="_blank" allowfullscreen="true" allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share" class="fa fa-facebook" v-bind="attrs" v-on="on"></a> -->
              <a
                :href="
                  'https://www.facebook.com/sharer/sharer.php?u=' +
                  encodeURIComponent(getURLShareLink()) +
                  '&layout&size&width=77&height=20&appId'
                "
                target="_blank"
                allowfullscreen="true"
                allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"
                class="fa fa-facebook"
                v-bind="attrs"
                v-on="on"
              ></a>
            </template>
            <span>Ajouter à Facebook</span>
          </v-tooltip>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <!-- <a :href="'https://www.linkedin.com/shareArticle?mini=true&url=www.recrutementintegral.com%2Foffres-d-emplois%2F' + encodeURIComponent(jobIdURLSearchParams(jobDescription.Name)) + '&title='+encodeURIComponent(jobDescription.AVTRRT__Job_Title__c)+'&source=Linkedin'" target="_blank" class="fa fa-linkedin" allowfullscreen="true" allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share" v-bind="attrs" v-on="on"></a> -->
              <a
                :href="
                  'https://www.linkedin.com/shareArticle?mini=true&url=' +
                  encodeURIComponent(getURLShareLink()) +
                  '&title=' +
                  encodeURIComponent(jobDescription.AVTRRT__Job_Title__c) +
                  '&source=Linkedin'
                "
                target="_blank"
                class="fa fa-linkedin"
                allowfullscreen="true"
                allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"
                v-bind="attrs"
                v-on="on"
              ></a>
              <!-- <iframe :src="'https://www.facebook.com/plugins/share_button.php?href=https%3A%2F%2Fwww.recrutementintegral.com%2Foffres-d-emplois%2F%3FjobName%3D' + jobIdURLSearchParams(jobDescription.Name) + '&layout&size&width=77&height=20&appId'" width="77" height="20" style="border:none;overflow:hidden" scrolling="no" frameborder="0" allowfullscreen="true" allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"></iframe> -->
            </template>
            <span>Ajouter à LinkedIn</span>
          </v-tooltip>
        </div>
      </div>
      <div>
        <h2 class="text-h5 font-weight-bold pt-5 pb-3 secondary--text">Description de l'emploi:</h2>
        <p v-html="jobDescription.AVTRRT__Job_Description_Rich__c"></p>
      </div>

      <!-- <div class="mb-10">
        <a href="mailto:cv@recrutementintegral.com">cv@recrutementintegral.com</a>
      </div> -->
      <v-row>
        <v-col :cols="$vuetify.breakpoint.xs ? $vuetify.breakpoint.width < 730 ? 12 : 6 : 4" class="text-center">
          <v-btn @click="showForm()" class="rounded-md secondary">
            <span class="font-weight-bold">Postuler</span>
          </v-btn>
        </v-col>
        <v-col :cols="$vuetify.breakpoint.xs ? $vuetify.breakpoint.width < 730 ? 12 : 6:  8" class="text-center">
          <v-btn
              @click="
              mode == 'recentJob'
              ? showRecentJob() + deleteURLSearchParams('jobName')
              : showList();
              deleteURLSearchParams('jobName');
              "
          style="border: 2px solid #003145"
          :class="$vuetify.breakpoint.xs ? 'py-6' : ''">
            <span class="font-weight-bold">
              Voir nos autres <br v-if="$vuetify.breakpoint.xs" /> offres d'emplois
            </span>
            <!-- <span
              v-else
              class="font-weight-bold"
              style="max-width: 350px">
            Voir nos autres offres
            </span> -->
            </v-btn>
        </v-col>
      </v-row>
      <!-- <div class="d-flex justify-space-around align-center my-2">
        <v-btn @click="showForm()" class="rounded-md secondary">
          <span class="font-weight-bold">Postuler</span>
        </v-btn>
        <v-btn
            @click="
            mode == 'recentJob'
            ? showRecentJob() + deleteURLSearchParams('jobName')
            : showList();
            deleteURLSearchParams('jobName');
            "
        style="border: 2px solid #003145">
          <span
            class="font-weight-bold"
            style="max-width: 350px"

            >Voir nos autres offres d'emplois</span
          >
        </v-btn>
      </div> -->
    </v-container>

    <!-- Formulaire Postuler -->
    <v-container
      class="transparent"
      fluid="fluid"
      v-show="pageForm == true"
      :style="'background-color:' + bgcolor"
    >
      <v-card
        elevation="0"
        class="rounded-md transparent"
        :class="$vuetify.breakpoint.xs ? 'px-0' : 'px-6' "
        :style="'background-color:' + bgcolor"
      >
        <v-form ref="form" v-model="valid" lazy-validation class="pt-6">
          <v-card-text class="pb-0 mb-0 pl-0">
            <!-- <v-btn @click="showList();deleteURLSearchParams('jobName')" class="font-weight-bold" style="border: 2px solid #003145"> -->
            <v-btn
              @click="
                loading
                  ? null
                  : !pageFormSpont
                  ? showJobDetail(jobDescription)
                  : showList()
              "
              class="font-weight-bold"
              style="border: 2px solid #003145"
            >
              <span> Retour </span>
            </v-btn>
          </v-card-text>
          <v-card-text class="d-flex align-center pt-0">
            <!-- <img src="https://www.recrutementintegral.com/wp-content/themes/theme-recrutement/images/svg/logo.svg" alt="" class="logo mr-16" width="100px"> -->
            <v-card-text class="text-h4 px-0 font-weight-medium text-center">{{
              pageFormSpont ? i18n.t("pageFormSpontTitle") : i18n.t("pageFormTitle")
            }}</v-card-text>
          </v-card-text>
          <v-row>
            <v-col class="py-0">
              <v-text-field
                label="Prénom*"
                name="first-name"
                :disabled="loading"
                v-bind:rules="formRules.firstNameRules"
                v-model="form.firstName"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col class="py-0">
              <v-text-field
                label="Nom*"
                name="last-name"
                :disabled="loading"
                v-bind:rules="formRules.lastNameRules"
                v-model="form.lastName"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col class="py-0">
              <v-text-field
                label="Courriel*"
                name="email"
                :disabled="loading"
                v-bind:rules="formRules.emailRules"
                v-model="form.email"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col class="py-0">
              <v-text-field
                label="Téléphone*"
                name="telephone"
                :disabled="loading"
                v-bind:rules="formRules.phoneRules"
                v-model="form.phone"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col class="py-0">
              <v-select
                v-if="pageFormSpont"
                :disabled="loading"
                label="Catégorie*"
                v-bind:rules="formRules.categoryRules"
                :items="categoriesList"
                v-model="form.categorieChoisi"
              ></v-select>
            </v-col>
          </v-row>
          <v-row>
            <v-col class="py-0">
              <v-file-input
                accept=".pdf,.doc,.docx"
                truncate-length="50"
                :disabled="loading"
                show-size
                label="Curriculum Vitae"
                v-model="form.fileUpload"
              ></v-file-input>
            </v-col>
          </v-row>
          <v-row>
            <v-col class="pt-0">
              <v-text-field
                label="LinkedIn URL"
                :disabled="loading"
                v-model="form.linkedInURL"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-textarea
                style="border-top: 1px solid black"
                outlined
                v-model="form.comment"
                v-bind:disabled="loading"
                :hint="i18n.t('commentHint')"
                counter="counter"
                height="100px"
                :placeholder="i18n.t('commentPlaceholder')"
                solo
                no-resize
                class="rounded-lg"
              ></v-textarea>
            </v-col>
          </v-row>
          <v-card-text class="pt-0">*Champs obligatoires</v-card-text>
          <v-card-actions class="d-flex justify-space-around pt-2 flex-wrap">
            <v-btn
              class="rounded-md py-2 mb-4 secondary"
              :width="pageForm && !pageFormSpont ? '40%' : ''"
              @click="loading ? null : validate()"
              :disabled="!valid"
            >
              <span
                class="font-weight-bold"
              >
                {{
                  pageFormSpont ? $vuetify.breakpoint.xs ? i18n.t("pageFormButton") : i18n.t("pageFormSpontButton") : i18n.t("pageFormButton")
                }}</span
              >
              <v-progress-circular
                v-if="loading"
                class="ml-4"
                indeterminate="indeterminate"
                size="23"
              ></v-progress-circular>
            </v-btn>
            <!-- <v-btn v-if="pageFormSpont" class="mb-4" style="border: 2px solid #003145">
              <span class="font-weight-bold" style="max-width: 350px" @click="showList">Voir nos autres offres d'emplois</span>
            </v-btn> -->
          </v-card-actions>
        </v-form>
      </v-card>
    </v-container>
    <!-- Page de remerciement -->
    <v-container
      fluid="fluid"
      v-show="formSend == true"
      class="text-center transparent"
      :style="'background-color:' + bgcolor"
    >
      <v-row>
        <v-col cols="12" class="d-flex justify-center align-center">
          <v-card class="transparent" elevation="0" width="700px" :style="'background-color:' + bgcolor">
            <v-row>
              <v-col class="pt-2 px-0">
                <v-card-text class="text-h5" v-if="pageFormSpont && formSend"
                  >Merci, nous sommes reconnaissant d’avoir reçu votre candidature
                  spontanée. Votre dossier sera évalué par un de nos spécialistes en
                  recrutement.</v-card-text
                >
                <v-card-text class="text-h5" v-if="!pageFormSpont && formSend">
                  Merci d’avoir postulé sur le poste <b>{{ jobDescription.AVTRRT__Job_Title__c }}</b>. Votre dossier sera rapidement
                  évalué par un de nos spécialistes en recrutement.</v-card-text
                >
                <v-btn
                  @click="
                  formSend = false;
                  showList();
                  deleteURLSearchParams('jobName');"
                  class="secondary"
                  :class="$vuetify.breakpoint.xs ? 'py-6' : ''">
                  <span class="font-weight-bold">
                    Voir nos autres <br v-if="$vuetify.breakpoint.xs" /> offres d'emplois
                  </span>
                </v-btn>
              </v-col>
            </v-row>
          </v-card>
        </v-col>
      </v-row>
      <!-- <p>Vous avez bien postuler pour l'emploi: {{ selectedJob }}</p> -->
      <!-- <p>Nous vous contacterons dès que possible</p> -->
    </v-container>

    <v-container class="transparent" v-show="duplicateView == true" fluid="fluid" :style="'background-color:' + bgcolor">
      <div class="text-center">
        <v-card-text class="text-h3">Oops !</v-card-text>
      </div>
      <v-row>
        <v-col cols="12" class="d-flex justify-center align-center">
          <v-card elevation="0" width="700px" :style="'background-color:' + bgcolor">
            <v-row>
              <v-col class="pt-2 px-0">
                <v-card-text class="text-h5 text-center"
                  >Le système m'indique que vous avez déjà appliqué pour ce poste. Afin de
                  bien gérer les demandes d'emploi, vous ne pouvez pas appliqué sur le
                  même poste.</v-card-text
                >
              </v-col>
            </v-row>
          </v-card>
        </v-col>
      </v-row>
      <v-row>
        <v-col class="text-center">
          <v-btn
            @click="
            formSend = false;
            showList();
            deleteURLSearchParams('jobName');"
            class="secondary">
            <span class="font-weight-bold">
              Retour à la liste
              </span>
          </v-btn>
        </v-col>
      </v-row>
    </v-container>

    <v-container class="transparent" v-show="errorView == true" fluid="fluid" :style="'background-color:' + bgcolor">
      <!-- <div class="text-center">
        <v-card-text class="text-h3">Erreur</v-card-text>
      </div> -->
      <v-row>
        <v-col cols="12" class="d-flex justify-center align-center">
          <v-card elevation="0" width="700px" :style="'background-color:' + bgcolor">
            <v-row>
              <v-col class="pt-2 px-0">
                <v-card-text class="text-h4 text-center"
                  >Désolé, une erreur est survenu lors de la soumission de votre candidature.</v-card-text>
                <v-card-text class="text-h5 text-center"
                  >Veuillez réessayer ou nous contacter si le problème persiste.</v-card-text
                >
              </v-col>
            </v-row>
          </v-card>
        </v-col>
      </v-row>
      <v-row>
        <v-col class="text-center">
          <v-btn
            @click="
            formSend = false;
            showList();
            deleteURLSearchParams('jobName');"
            class="secondary">
            <span class="font-weight-bold">
              Retour à la liste
              </span>
          </v-btn>
        </v-col>
      </v-row>
    </v-container>

    <v-container class="transparent" v-show="loadingView == true" fluid="fluid" :style="'background-color:' + bgcolor">
      <v-row
        class="fill-height"
        align-content="center"
        justify="center"
      >
        <v-col
          class="text-subtitle-1 text-center"
          cols="12"
        >
          <v-card-text class="text-h5 text-center">Veuillez patienter.</v-card-text>
          <v-card-text class="text-h5 text-center">Traitement de votre demande en cours...</v-card-text>
        </v-col>
        <v-col cols="6">
          <v-progress-linear
            color="secondary"
            indeterminate
            rounded
            height="6"
          ></v-progress-linear>
        </v-col>
      </v-row>
    </v-container>
  </v-container>
</template>

<script>
import axios from "axios";
import i18n from "@/i18n";
import icon from "./SvgLoader";
import { load } from "recaptcha-v3";

export default {
  name: "HelloWorld",

  props: {
    job: String,
    mode: String,
    bgcolor: String,
  },
  components: { icon },

  data: () => ({
    loadingView:false,
    errorView:false,
    duplicateView: false,
    valid: true,
    formSend: false,
    pageForm: false,
    noActiveJob: false,
    jobDescription: null,
    jobLoaded: false,
    loading: false,
    pageAccueil: false,
    emploisVedette: false,
    emploisRecent: false,
    publishedJob: false,
    pageFormSpont: false,
    pageJobDetail: false,
    pageJobList: true,
    jobListLoaded: false,
    jobList: [],
    recentJob: [],
    vedetteList: [],
    publishedJobList: [],
    categoriesList: [],
    categories: [],
    categorieFiltre: [],
    selectedJob: "",
    i18n: i18n,
    // jobId: this.job,

    form: {
      jobName: "",
      firstName: "",
      lastName: "",
      email: "",
      phone: "",
      fileUpload: null,
      linkedInURL: "",
      categorieChoisi: "",
      comment: "",
    },
  }),

  async created() {
    // if(this.getURLSearchParams('jobName')){
    //   this.$emit('mode','emploi');
    //   this.$emit('job',this.getURLSearchParams('jobName'));
    //   // this.mode = "emploi"
    //   // this.job = this.getURLSearchParams('jobName')
    // }
    var vm = this;
    vm.scrollToTop();

    if (vm.dataReturnJob || (vm.dataReturnJob && vm.dataReturnMode)) {
      if(vm.dataReturnJob && vm.dataReturnMode){
        vm.resetUrl();
        vm.getJobList();
        vm.getCategoryPickList();
      } else {
        vm.getJobDetail(vm.dataReturnJob).then(function(){
          if(vm.jobDescription){
          vm.pageJobDetail = true;
          vm.pageAccueil = false;
          vm.pageJobList = false;
          } else {
            vm.resetUrl();
            vm.getJobList();
            vm.getCategoryPickList();
          }
          });

      }
    } else if (vm.mode == "spont" && !vm.dataReturnJob || vm.dataReturnMode == "spont" && !vm.dataReturnJob){
      vm.getCategoryPickList();
      vm.formSpont();
      vm.deleteURLSearchParams('jobName');
    }
    // else if (vm.mode == "vedette" || vm.dataReturnMode == "vedette") {
    //   vm.getVedette();
    //   vm.pageAccueil = true;
    //   vm.emploisVedette = true;
    //   vm.pageJobList = false;
    // } else if (vm.mode == "publishedJob" || vm.dataReturnMode == "publishedJob") {
    //   vm.getPublishedJob();
    //   vm.pageAccueil = true;
    //   vm.publishedJob = true;
    //   vm.pageJobList = false;
    // }
    else if (vm.mode == "jobList" && !vm.dataReturnJob || vm.dataReturnMode == "jobList" && !vm.dataReturnJob) {
      vm.getJobList();
      vm.getCategoryPickList();
      vm.pageJobList = true;
      vm.pageAccueil = false;
    } else if (vm.mode == "recentJob" && !vm.dataReturnJob || vm.dataReturnMode == "recentJob" && !vm.dataReturnJob) {
      vm.showRecentJob();
    } else {
      vm.resetUrl();
      vm.getJobList();
      vm.getCategoryPickList();
    }
  },
  mounted: function () {
  },
  computed: {
    formRules() {
      return {
        firstNameRules: [
          (v) => !!v || i18n.t("firstNameRequired"),
          (v) => (v && v.length >= 2 && v.length <= 20) || i18n.t("firstNameLength"),
          (v) =>
            /^([A-Za-zàâäèéêëîïôœùûüÿçÀÂÄÈÉÊËÎÏÔŒÙÛÜŸÇ]{1})+([[A-Za-zàâäèéêëîïôœùûüÿçÀÂÄÈÉÊËÎÏÔŒÙÛÜŸÇ]+[,.]?[ ]?|[A-Za-z]+['-àâäèéêëîïôœùûüÿçÀÂÄÈÉÊËÎÏÔŒÙÛÜŸÇ]]?)+$/.test(
              v
            ) || i18n.t("firstNameValid"),
        ],
        lastNameRules: [
          (v) => !!v || i18n.t("lastNameRequired"),
          (v) => (v && v.length >= 2 && v.length <= 20) || i18n.t("lastNameLength"),
          (v) =>
            /^([A-Za-zàâäèéêëîïôœùûüÿçÀÂÄÈÉÊËÎÏÔŒÙÛÜŸÇ]{1})+([[A-Za-zàâäèéêëîïôœùûüÿçÀÂÄÈÉÊËÎÏÔŒÙÛÜŸÇ]+[,.]?[ ]?|[A-Za-z]+['-àâäèéêëîïôœùûüÿçÀÂÄÈÉÊËÎÏÔŒÙÛÜŸÇ]]?)+$/.test(
              v
            ) || i18n.t("lastNameValid"),
        ],
        descriptionRules: [(v) => !!v || i18n.t("descriptionRequired")],
        emailRules: [
          (v) => !!v || i18n.t("emailRequired"),
          (v) =>
            /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) || i18n.t("emailValid"),
        ],
        phoneRules: [
          (v) => !!v || i18n.t("phoneRequired"),
          (v) => (v && v.length >= 2 && v.length <= 100) || i18n.t("phoneLength"),
          (v) =>
            /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/.test(v) ||
            i18n.t("phoneValid"),
        ],
        categoryRules: [(v) => !!v || i18n.t("categoryRequired")],
        fileRules: [
          (v) => !!v || i18n.t("fileRequired"),
          (v) => (v && v.size <= 5900000) || i18n.t("fileLength"),
        ],
      };
    },
    dataReturnJob() {
      let data = "";
      const urlParams = new URLSearchParams(window.location.search);
      const jobName = urlParams.get("jobName")
        ? urlParams.get("jobName").replace("/", "")
        : null;
      if (jobName) {
        return (data = jobName);
      }
      if (this.job) {
        return (data = this.job);
      }
      return data;
    },

    dataReturnMode() {
      let data = "";
      const urlParams = new URLSearchParams(window.location.search);
      const mode = urlParams.get("mode") ? urlParams.get("mode").replace("/", "") : null;
      if (mode) {
        return (data = mode);
      }
      return data;
    },
    grouped() {
      let groups = [];
      this.jobList.forEach((item) => {
        groups[item.AVTRRT__Job_Category__c] = groups[item.AVTRRT__Job_Category__c] || [];
        groups[item.AVTRRT__Job_Category__c].push(item);
      });
      // groups = groups.sort((a,b)=>a.getTime()-b.getTime());
      return groups;
    },
    // filteredCategories(){
    //     let filteredCategories = this.grouped.filter((categorie) => {

    //    return
    // })
    // return filteredCategories;
    // },
  },
  watch: {},
  methods: {
    async getCategoryPickList() {
      var vm = this;
      await axios
        .post(process.env.VUE_APP_RESTURL, {
          method: "getCategoryPickList",
        })
        .then(function (response) {
          if (response.status === 200 && response.data) {
            var data = JSON.parse(response.data);

            vm.categoriesList = data;
            // vm.categoriesList.unshift('Aucun filtre')
            // vm.jobLoaded = true
          }
        })
        .catch(function (error) {
          vm.jobLoaded = true;
          console.log(error);
        });
    },
    async getJobList() {
      var vm = this;

      await axios
        .post(process.env.VUE_APP_RESTURL, {
          method: "getJobList",
        })
        .then(function (response) {
          if (response.status === 200 && response.data) {
            var data = JSON.parse(response.data);
            vm.jobListLoaded = true;
            vm.jobList = data;
          }
        })
        .catch(function (error) {
          vm.jobListLoaded = true;
          console.log(error);
        });
    },
    async getRecentJob() {
      var vm = this;
      await axios
        .post(process.env.VUE_APP_RESTURL, {
          method: "getRecentJob",
        })
        .then(function (response) {
          if (response.status === 200 && response.data) {
            var data = JSON.parse(response.data);
            vm.recentJob = data;
          }
        })
        .catch(function (error) {
          vm.jobLoaded = true;
          console.log(error);
        });
    },
    async getVedette() {
      var vm = this;
      await axios
        .post(process.env.VUE_APP_RESTURL, {
          method: "getVedette",
        })
        .then(function (response) {
          if (response.status === 200 && response.data) {
            var data = JSON.parse(response.data);

            vm.vedetteList = data;
          }
        })
        .catch(function (error) {
          vm.jobLoaded = true;
          console.log(error);
        });
    },
    async getPublishedJob() {
      var vm = this;
      await axios
        .post(process.env.VUE_APP_RESTURL, {
          method: "getPublishedJob",
        })
        .then(function (response) {
          if (response.status === 200 && response.data) {
            var data = JSON.parse(response.data);

            vm.publishedJobList = data;
          }
        })
        .catch(function (error) {
          vm.jobLoaded = true;
          console.log(error);
        });
    },

    async getJobDetail(jobName) {
      var vm = this;
      await axios
        .post(process.env.VUE_APP_RESTURL, {
          jobName: jobName,
          method: "getJobDetail",
        })
        .then(function (response) {
          if (response.status === 200 && response.data) {
            var data = JSON.parse(response.data);


            vm.jobDescription = data;
            vm.jobLoaded = true;
            vm.selectedJob = jobName;
          }
        })
        .catch(function (error) {
          vm.jobLoaded = false;
          console.log(error);
        });
    },

    changeDate(date) {
      let newDate = date;
      let goodDate = new Date(newDate);
      let humanFormat = goodDate.toLocaleDateString("fr-FR");
      return humanFormat;
    },

    filtrerCategorie(job) {
      if (this.categorieFiltre == "" || this.categorieFiltre == "Aucun filtre") {
        return true;
      } else if (this.categorieFiltre != job.AVTRRT__Job_Category__c) {
        return false;
      }
      return true;
    },

    showJobDetail(job) {
      this.scrollToTop();
      this.emploisRecent = false;
      this.pageJobDetail = true;
      this.pageJobList = false;
      this.pageAccueil = false;
      this.pageForm = false;
      this.pageFormSpont = false;
      this.jobDescription = job;
      this.selectedJob = job.Name;
      this.getJobDetail(job.Name);
    },

    validate() {
      var vm = this;
      if (vm.$refs.form.validate()) {
        vm.loadingView = true;
        vm.pageForm = false;
        vm.loading = true;
        load(process.env.VUE_APP_RECAPTCHA).then((recaptcha) => {
          recaptcha.execute("validationQuote").then(function (token) {
            axios
              .post(process.env.VUE_APP_RESTURL, {
                method: "validation",
                token: token,
              })
              .then(function (response) {
                if (response.status === 200) {
                  if (vm.pageFormSpont) {
                    vm.sendContactSpont();
                  }
                  if (!vm.pageFormSpont) {
                    vm.sendJobApplicant();
                  }
                } else {
                  vm.loading = false;
                }
              })
              .catch(function (error) {
                vm.loading = false;
                console.log(error);
              });
          });
        });
      }
    },
    async sendJobApplicant() {
      this.scrollToTop();
      this.loading = true;
      var vm = this;
      // let utmSource = '', utmCampaign = '', utmMedium = '', utmTerm = '', gclid = '';
      // const cookieUTMS = this.getCookie('cookie_utms')?JSON.parse(this.getCookie('cookie_utms')):undefined;
      // if(cookieUTMS){
      //     utmSource = decodeURIComponent(cookieUTMS['utmSource']);
      //     utmCampaign = decodeURIComponent(cookieUTMS['utmCampaign']);
      //     utmMedium = decodeURIComponent(cookieUTMS['utmMedium']);
      //     utmTerm = decodeURIComponent(cookieUTMS['utmTerm']);
      //     gclid = decodeURIComponent(cookieUTMS['gclid']);
      // }
      const file = await this.convertFileUpload(this.form.fileUpload);
      axios
        .post(process.env.VUE_APP_RESTURL, {
          jobName: this.selectedJob,
          // jobInterestName: this.jobInterestOptionText(),
          firstName: this.form.firstName,
          lastName: this.form.lastName,
          email: this.form.email,
          phone: this.form.phone,
          category:
            this.jobDescription.AVTRRT__Job_Category__c || this.form.categorieChoisi,
          comment: this.form.comment,
          linkedInURL: this.form.linkedInURL,
          // questions: this.jobInterestQuestionToObject(),
          fileUpload: file,
          fileUploadType: this.form.fileUpload ? this.form.fileUpload.type : "",
          fileUploadName: this.form.fileUpload ? this.form.fileUpload.name : "",
          leadSource: 'Entrant candidature pour un poste',
          // utmSource: utmSource,
          // utmCampaign: utmCampaign,
          // utmMedium: utmMedium,
          // utmTerm: utmTerm,
          // gClId: gclid,
          // lang: this.lang,
          // source: this.source,
          method: "createJobApplicant",
        })
        .then(function (response) {
          // console.log(response);
          if (response.status === 200) {
            // console.log('data not parse',response.data);
            // var res = JSON.parse(response.data);
            var res = response.data;
            // console.log('parsed data',res)
            if (res === "Error" ) {
              vm.errorView = true;
              vm.loadingView = false;
              vm.pageForm = false;
              vm.formSend = false;
              vm.loading = false;
              vm.duplicateView = false;
            }
            if (res === "Success") {
              vm.formSend = true;
              vm.loadingView = false;
              vm.errorView = false;
              vm.pageForm = false;
              vm.loading = false;
              vm.duplicateView = false;
            }

            if (res === "Duplicate") {
              vm.duplicateView = true;
              vm.loadingView = false;
              vm.errorView = false;
              vm.pageForm = false;
              vm.loading = false;
              vm.snackbar = true;
            }
            vm.loading = false;
            vm.snackbar = true;
          } else {
            vm.loading = false;
            vm.snackbar = true;
          }
        })
        .catch(function (error) {
          if(error.response.status === 500){
            vm.errorView = true;
            vm.loadingView = false;
            vm.pageForm = false;
            vm.formSend = false;
            vm.loading = false;
            vm.duplicateView = false;
          }
          vm.loading = false;
          vm.snackbar = true;
          console.log(error);
        });
    },
    async sendContactSpont() {
      this.scrollToTop();
      this.loadingView = true;
      var vm = this;
      const file = await this.convertFileUpload(this.form.fileUpload);
      axios
        .post(process.env.VUE_APP_RESTURL, {
          // jobName: this.selectedJob,
          // jobInterestName: this.jobInterestOptionText(),
          firstName: this.form.firstName,
          lastName: this.form.lastName,
          email: this.form.email,
          phone: this.form.phone,
          category: this.form.categorieChoisi,
          comment: this.form.comment,
          linkedInURL: this.form.linkedInURL,
          // questions: this.jobInterestQuestionToObject(),
          fileUpload: file,
          fileUploadType: this.form.fileUpload ? this.form.fileUpload.type : "",
          fileUploadName: this.form.fileUpload ? this.form.fileUpload.name : "",
          leadSource: 'Entrant candidature spontanée',
          // utmSource: utmSource,
          // utmCampaign: utmCampaign,
          // utmMedium: utmMedium,
          // utmTerm: utmTerm,
          // gClId: gclid,
          // lang: this.lang,
          // source: this.source,
          method: "createContact",
        })
        .then(function (response) {
          // console.log(response);
          if (response.status === 200) {

            // console.log('data not parse',response.data);
            // var res = JSON.parse(response.data);
            var res = response.data;
            // console.log('parsed data',res)
            if (res === "Error") {
              vm.errorView = true;
              vm.loadingView = false;
              vm.pageForm = false;
              vm.formSend = false;
              vm.loading = false;
              vm.duplicateView = false;
            }
            if (res === "Success") {
              vm.formSend = true;
              vm.loadingView = false;
              vm.pageForm = false;
              vm.loading = false;
            }

            if (res === "Duplicate") {
              vm.duplicateView = true;
              vm.loadingView = false;
              vm.pageForm = false;
              vm.loading = false;
              vm.snackbar = true;
            }
            vm.loading = false;
            vm.snackbar = true;
          } else {
            vm.loading = false;
            vm.snackbar = true;
          }
        })
        .catch(function (error) {
          if(error.response.status === 500){
            vm.errorView = true;
            vm.loadingView = false;
            vm.pageForm = false;
            vm.formSend = false;
            vm.loading = false;
            vm.duplicateView = false;
          }
          vm.loading = false;
          vm.snackbar = true;
          console.log(error);
        });
    },
    async convertFileUpload(file) {
      if (file) {
        const reader = new FileReader();
        return new Promise((resolve) => {
          reader.addEventListener(
            "load",
            () => {
              resolve((window.result = reader.result.split(",").pop()));
            },
            false
          );
          reader.readAsDataURL(file);
        });
      }
    },
    showForm() {
      this.scrollToTop();
      this.pageForm = true;
      this.pageJobDetail = false;
      this.pageJobList = false;
      this.pageFormSpont = false;
    },

    formSpont() {
      this.scrollToTop();
      this.pageFormSpont = true;
      this.pageJobList = false;
      this.pageJobDetail = false;
      this.pageForm = true;
      this.pageAccueil = false;
    },

    showList() {
      this.scrollToTop();
      this.resetUrl();
      this.pageJobList = true;
      this.errorView = false;
      this.duplicateView = false;
      this.emploisRecent = false;
      this.pageJobDetail = false;
      this.pageForm = false;
      this.pageAccueil = false;
      this.pageFormSpont = false;
      this.categorieFiltre = [];

      if (this.jobListLoaded != true) {
        this.getJobList();
        this.getCategoryPickList();
      }
    },
    showRecentJob() {
      this.scrollToTop();
      this.getRecentJob();
      this.emploisRecent = true;
      // this.pageAccueil = true
      this.pageJobList = false;
      this.pageJobDetail = false;
      this.pageForm = false;
      this.pageAccueil = false;
      this.pageFormSpont = false;
    },

    returnAccueil() {
      this.scrollToTop();
      this.pageAccueil = true;
      this.pageJobList = false;
      this.emploisRecent = true;
      this.emploisVedette = false;
      this.getRecentJob();
    },
    categoryDataLength(object) {
      if (object) {
        var length = Object.keys(object).length;
        return length;
      }
    },

    // remove(item) {
    //       this.categorieFiltre = this.categorieFiltre.filter(find => find !== item);
    // },
    // getURLSearchParams(urlPart) {
    //   const urlParams = new URLSearchParams(window.location.search);
    //   if(urlParams.has(urlPart)){
    //     return urlParams.get(urlPart);
    //   }
    // },
    // jobIdURLSearchParams(name,item) {
    //     const urlParams = new URLSearchParams(window.location.search);

    //     urlParams.set(name, item);

    //     if(urlParams.toString()){
    //       return "?" + urlParams.toString();

    //         // + '&'+  urlParams.toString();
    //     }
    //   else {
    //       return "?" + urlParams.toString();

    //     }
    // },
    updateUrl(name, item) {
      const urlParams = new URLSearchParams(window.location.search);
      urlParams.set(name, item);
      const newUrl = window.location.pathname + "?" + urlParams.toString();
      window.history.pushState({ path: newUrl }, "", newUrl);
    },
   resetUrl() {
      const newUrl = window.location.pathname
      window.history.pushState(null, null, newUrl);
    },
    getURLShareLink() {
      // const urlParams = new URLSearchParams(window.location.search);
      // if(urlParams.toString()){
      // console.log(window.location.href);
      // }
      return window.location.href;
    },
    deleteURLSearchParams(param) {
      const urlParams = new URLSearchParams(window.location.search);
      if (urlParams.has(param)) {
        urlParams.delete(param);
        if (urlParams.toString()) {
          const newUrl = window.location.pathname + "?" + urlParams.toString();
          window.history.pushState(null, null, newUrl);
        } else {
          const newUrl = window.location.pathname;
          window.history.pushState(null, null, newUrl);
        }
      }
    },
    scrollToTop() {
      const getOffset = (element, horizontal = false) => {
        if (!element) return 0;
        return (
          getOffset(element.offsetParent, horizontal) +
          (horizontal ? element.offsetLeft : element.offsetTop)
        );
      };
      const element = document.getElementById("app");
      const X = getOffset(element) - (getOffset(element) / 100) * 30;
      window.scrollTo(0, X);
    },
  },
};
</script>

<style scoped>
/* .field{
  width: 650px;
} */

.hover:hover {
  background-color: rgb(202, 202, 202);
}

::v-deep legend{
  width: unset!important;
}

.fa {
  padding: 12px;
  font-size: 30px;
  width: 50px;
  text-align: center;
  text-decoration: none;
  margin: 5px 2px;
  border: 1px solid #003145;
}

.conteneur {
  width: 350px;
  height: 280px;
}
.v-file-input {
  flex-direction: row-reverse;
}
/* .conteneur h2{
  width: 350px;
  height: 85px;
}

.conteneur p{
  width: 350px;
  height: 24px;
  color: black;
  font-weight: bold;
}

.conteneur v-btn{
  width: 70px;
  height: 35px;
} */

::v-deep .arrow-container {
  position: absolute;
  display: block;
  right: 45px;
  width: 70px;
  height: 25px;
  transition: all 0.4s;
}
::v-deep .arrow-container:hover {
  position: absolute;
  display: block;
  right: 0px;
  width: 90px;
  height: 25px;
}
::v-deep .box {
  width: 40px;
}

::v-deep .default-color-svg {
  fill: #003145 !important;
}

::v-deep .v-text-field-width {
  width: 400px;
}

</style>
