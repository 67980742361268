<template>
  <div data-vuetify>
    <link rel="preconnect" href="https://fonts.googleapis.com">
    <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin>
    <link href="https://fonts.googleapis.com/css?family=Nunito+Sans:400,700&display=swap" rel="stylesheet">
    <link rel="preconnect" href="https://cdn.jsdelivr.net">
    <v-app class="rounded pa-0 transparent">
      <v-main >
        <!-- vedette -->
        <!-- jobList -->
        <!-- recentJob -->
        <!-- emploi -->
        <!-- publishedJob -->
        <HelloWorld
        :job="job || ''"
        :mode="mode || ''"
        :bgcolor="bgcolor || ''" />
      </v-main>
    </v-app>
  </div>
</template>

<script>
import HelloWorld from './components/HelloWorld';

export default {
  name: 'App',
  props: {
    job: String,
    mode: String,
    bgcolor: String,
  },

  components: {
    HelloWorld,
  },

  data: () => ({
    //
  }),
  async created(){

  },
  methods: {
  }
};
</script>

<style scoped lang="scss">
@import "./assets/css/main.scss";
[data-vuetify]{overflow-y:hidden!important;}
// @import url('https://fonts.googleapis.com/css?family=Nunito+Sans:400,700&display=swap');
@import url('https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css');
@import url('https://cdnjs.cloudflare.com/ajax/libs/MaterialDesign-Webfont/7.1.96/css/materialdesignicons.min.css');
:global(.grecaptcha-badge){visibility:hidden}
:global(.pac-item){font-family:'Nunito Sans',sans-serif !important;font-weight:400 !important;color:rgba(0,0,0,.87) !important;line-height:40px !important;font-size:16px;cursor:pointer}
:global(.pac-item-query){font-family:'Nunito Sans',sans-serif !important;font-size:16px}
:global(.pac-item:hover){background-color:rgb(246,246,246) !important;transition: .3s cubic-bezier(.25,.8,.5,1)}
:global(.pac-container){color:rgba(0,0,0,.87)!important;max-height:166px;overflow-y:auto}
:global(.pac-icon,.pac-icon-marker){width:0 !important;height:0 !important;background:none !important;background-image:none !important}
::v-deep .v-application--wrap {min-height: 100% !important;}
:global(div>.rounded){border-radius:4px!important}
:global(.transparent){background-color:transparent !important;border-color:transparent !important}

</style>
